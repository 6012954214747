.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
}

.navbar {
  width: 100%;
  transition: all 0.3s ease;
  background: rgba(232, 227, 224, 0.95);
  backdrop-filter: blur(10px);
  padding: 20px 0;
}

.navbar-scrolled {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 15px 0;
}

.navbar-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-brand a {
  font-size: clamp(1rem, 4vw, 1.5rem);
  font-weight: 800;
  color: #693838;
  text-decoration: none;
  letter-spacing: 1.2px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.navbar-links {
  display: flex;
  align-items: center;
  gap: 30px;
}

.nav-link {
  color: #ffffff;
  text-decoration: none;
  font-weight: 600;
  font-size: 1.1rem;
  transition: color 0.3s ease;
  position: relative;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.nav-link::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 0;
  height: 2px;
  background: #007bff;
  transition: width 0.3s ease;
}

.nav-link:hover::after {
  width: 100%;
}

.nav-button {
  background: #3498db;
  color: white;
  padding: 10px 20px;
  border-radius: 25px;
  text-decoration: none;
  font-weight: 600;
  transition: all 0.3s ease;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
  letter-spacing: 0.5px;
}

.nav-button:hover {
  background: #0056b3;
  transform: translateY(-2px);
}

.navbar-hamburger {
  display: none;
  flex-direction: column;
  gap: 6px;
  cursor: pointer;
  padding: 10px;
  z-index: 1000;
}

.navbar-hamburger span {
  display: block;
  width: 25px;
  height: 2px;
  background: #333;
  transition: all 0.3s ease;
}

/* Hamburger animation */
.navbar-hamburger.active span:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.navbar-hamburger.active span:nth-child(2) {
  opacity: 0;
}

.navbar-hamburger.active span:nth-child(3) {
  transform: rotate(-45deg) translate(7px, -7px);
}

@media (max-width: 992px) {
  .navbar-brand a {
    font-size: clamp(1rem, 3vw, 1.3rem);
  }

  .navbar-links {
    gap: 15px;
  }

  .nav-button {
    padding: 8px 16px;
  }
}

@media (max-width: 768px) {
  .navbar {
    padding: 15px 0;
  }

  .navbar-container {
    padding: 0 15px;
  }

  .navbar-links {
    top: 70px;
    padding: 15px;
  }

  .navbar-hamburger {
    display: flex;
  }

  .navbar-links {
    position: fixed;
    top: 80px;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.98);
    backdrop-filter: blur(10px);
    flex-direction: column;
    padding: 20px;
    gap: 20px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    transition: all 0.3s ease;
  }

  .navbar-links.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
  }

  .nav-button {
    width: 100%;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .navbar-container {
    padding: 0 15px;
  }
}
