.offer {
    padding: 74px 20px;
    text-align: center;
}

.offer h1 {
    margin-bottom: 2rem;
    color: #333;
    font-size: 2.5rem;
}

.offer-container {
    max-width: 1200px;
    margin: 0 auto;
}

.offer-intro {
    margin-bottom: 3rem;
    padding: 2rem;
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.offer-intro h2 {
    color: #007bff;
    margin-bottom: 1rem;
    font-size: 1.8rem;
}

.intro-text {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #666;
    max-width: 800px;
    margin: 0 auto;
}

.offer-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    margin: 3rem 0;
}

.offer-item {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.offer-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.offer-icon {
    font-size: 2.5rem;
    margin-bottom: 1rem;
}

.offer-item h3 {
    color: #333;
    margin-bottom: 1rem;
    font-size: 1.3rem;
}

.offer-item p {
    color: #666;
    line-height: 1.5;
}

.offer-cta {
    margin-top: 3rem;
    padding: 2rem;
    background: white;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.offer-cta p {
    font-size: 1.1rem;
    color: #666;
    margin-bottom: 1.5rem;
}

.license-info {
    margin-top: 1.5rem;
}

.license-badge {
    display: inline-block;
    padding: 0.5rem 1rem;
    background: #e9ecef;
    color: #495057;
    border-radius: 25px;
    font-weight: 500;
}

.license-info-discrete {
    margin-top: 20px;
    opacity: 0.7;
}

.license-badge-small {
    font-size: 0.9em;
    color: #666;
}

.offer-cta-slim {
    padding: 8px;
    margin: 10px auto;
    max-width: 300px;
}

.license-badge-compact {
    font-size: 0.85em;
    color: #666;
    display: inline-block;
}

@media (max-width: 992px) {
    .offer-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .offer {
        padding: 74px 15px;
    }

    .offer h1 {
        font-size: 2rem;
    }

    .offer-intro h2 {
        font-size: 1.5rem;
    }

    .intro-text {
        font-size: 1.1rem;
    }

    .offer-grid {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .offer-intro {
        padding: 1.5rem;
    }

    .offer-item {
        padding: 1.5rem;
    }
}

@media (max-width: 576px) {
    .offer {
        padding: 40px 15px;
    }
}