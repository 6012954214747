/* img{
    margin-top: 10px;
} */
.hope{
    margin-top: 10px;
}
.light{
  padding-top: 74px;
  text-align: center;
}
h1{
    text-align: center;
    margin-bottom: 1rem;
    color: #333;
}

.gallery-subtitle {
    color: #666;
    margin-bottom: 2rem;
    font-size: 1.1rem;
}

.gallery-row {
    margin-bottom: 20px;
}

.gallery-item {
    padding: 10px;
}

.image-container {
    position: relative;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    aspect-ratio: 16/9;
}

.image-container:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 15px rgba(0, 0, 0, 0.2);
}

.image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.5s ease;
}

.image-container:hover img {
    transform: scale(1.05);
}

.image-overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to top, rgba(0, 0, 0, 0.8), transparent);
    padding: 20px;
    color: white;
    opacity: 0;
    transition: opacity 0.3s ease;
}

.image-container:hover .image-overlay {
    opacity: 1;
}

.image-overlay span {
    display: block;
    font-size: 1.1rem;
    font-weight: 500;
}

@media (max-width: 992px) {
  .gallery-row {
    margin-bottom: 15px;
  }

  .gallery-item {
    padding: 8px;
  }
}

@media (max-width: 768px) {
  .gallery-item {
    padding: 5px;
  }

  .image-container {
    aspect-ratio: 4/3;
  }

  .image-overlay {
    opacity: 1;
    padding: 15px;
  }
}

@media (max-width: 576px) {
  .gallery-subtitle {
    font-size: 1rem;
  }
}