.ipsum {
    padding-top: 74px;
    text-align: center;
    width: 100%;
}

.contact-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 2rem auto;
    padding: 0 1rem;
}

.contact-item {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.contact-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.contact-icon {
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
}

.contact-info {
    text-align: center;
}

.contact-info h3 {
    color: #333;
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
}

.contact-info p {
    color: #666;
    margin: 0.25rem 0;
}

.contact-link {
    color: #007bff;
    text-decoration: none;
    font-weight: 500;
    font-size: 1.1rem;
    transition: color 0.3s ease;
    display: inline-block;
    padding: 0.5rem 1rem;
    border-radius: 25px;
    background: #f8f9fa;
    margin-top: 0.5rem;
}

.contact-link:hover {
    color: #0056b3;
    background: #e9ecef;
}

.map-container {
    padding: 20px;
    background: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    max-width: 1200px;
}

.map-frame {
    border-radius: 8px;
    border: 2px solid #eee;
    transition: all 0.3s ease;
}

.map-frame:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
}

@media (max-width: 992px) {
    .contact-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 768px) {
    .contact-container {
        grid-template-columns: 1fr;
        gap: 1rem;
    }

    .contact-item {
        padding: 1.5rem;
    }

    .map-container {
        padding: 10px;
        margin: 10px;
    }
    
    .map-frame {
        height: 300px;
    }
}

@media (max-width: 576px) {
    .contact-link {
        font-size: 1rem;
        padding: 0.4rem 0.8rem;
    }
}
